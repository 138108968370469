const { chain, compose, equals, find, propOr, where, filter, propEq, prop } = require('ramda');

const getContracts = propOr([], ['contracts']);

const getAffiliates = compose(
  chain(propOr([], 'affiliates')),
  propOr([], 'contracts')
);

const getAffiliate = (contractIdCrypt, affiliateId) => compose(
  find(
    where({
      id: equals(affiliateId),
      contractIdCrypt: equals(contractIdCrypt)
    })
  ),
  chain(propOr([], 'affiliates')),
  propOr([], 'contracts')
);

const getAffiliatesByContractId = (contractId) => compose(
  filter(propEq('contractIdCrypt', contractId)),
  chain(prop('affiliates')),
  prop('contracts')
);

const getDocuments = compose(
  chain(propOr([], 'documents')),
  propOr([], 'contracts')
);

const getDocumentsByContractId = (contractId) => compose(
  propOr([], 'documents'),
  find(propEq('contractId', contractId)),
  prop('contracts')
);

const getBillings = compose(
  chain(propOr([], 'billings')),
  propOr([], 'contracts')
);

const getBankAccounts = compose(
  propOr([], 'bankAccounts')
);

module.exports = {
  getContracts,
  getAffiliates,
  getAffiliate,
  getAffiliatesByContractId,
  getDocuments,
  getDocumentsByContractId,
  getBillings,
  getBankAccounts
};
