const { compose, filter, includes, prop, toLower, propOr, flatten } = require('ramda');

// tns == "Entreprise en nom propre"
// collectif == "Personne Morale"
const getOptionsByCompanyType = (options, user) => (
  compose(
    filter(
      compose(
        includes(toLower(propOr('', 'companyType', user))),
        flatten,
        prop('roles'))
    ))(options)
);

module.exports = {
  getOptionsByCompanyType
};
