const R = require('ramda');
const { __, and, compose, equals, includes, prop, propOr, toLower } = require('ramda');
const rolesList = require('../../refs/rolesList');

// tns == "Entreprise en nom propre"
// collectif == "Personne Morale"
const isTns = compose(
  includes(__, rolesList.TNS),
  toLower,
  propOr('', 'companyType')
);

const isCompany = compose(
  equals(rolesList.COMPANY),
  toLower,
  propOr('', 'companyType')
);

const isCompanyAndDisability = (user, sinistre) => (
  and(isCompany(user),
    equals('DISABILITY', prop('value', sinistre))
  ));

const isContractType = (value) => (key) => R.compose(
  R.equals(value),
  R.prop(key)
);

module.exports = {
  isTns,
  isCompany,
  isCompanyAndDisability,
  isContractType
};
