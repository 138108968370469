const { compose, length, gt, __ } = require('ramda');

const hasMoreThanOneElement = (element) => (
  compose(
    gt(__, 1),
    length
  )(element)
);

module.exports = {
  hasMoreThanOneElement
};
