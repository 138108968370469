const { propOr, both, compose, gte, prop, __ } = require('ramda');

const hasMinChildForInsured = (minChild) => both(
  compose(
    gte(__, minChild),
    Number.parseInt,
    prop('childrens')
  ),
  propOr(true, 'forInsured')
);

module.exports = hasMinChildForInsured;
