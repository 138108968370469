const { equals, compose, length } = require('ramda');

const hasOneElement = compose(
  equals(1),
  length
);

module.exports = {
  hasOneElement
};
