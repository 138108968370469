const R = require('ramda');
const RA = require('ramda-adjunct');

const getGroups = R.compose(
  R.values,
  R.groupBy(R.prop('category'))
);

const getDataset = R.applySpec({
  label: R.compose(R.prop('category'), R.head),
  data: R.map(R.prop('value')),
  backgroundColor: R.map(R.prop('color'))
});

const getDatasets = R.compose(
  R.map(getDataset),
  getGroups
);

const getLabels = R.compose(
  R.map(R.prop('label')),
  R.head,
  getGroups
);

const getValue = R.propOr(0, 'value');

const allValueEqualsZero = R.compose(
  R.all(R.equals(0)),
  R.map(getValue)
);

const ChartDataToDataset = (original) => {
  if (R.or(RA.isNilOrEmpty(original), allValueEqualsZero(original))) {
    return {
      labels: [],
      datasets: []
    };
  }
  const data = {
    labels: getLabels(original),
    datasets: getDatasets(original)
  };

  return data;
};

module.exports = ChartDataToDataset;
