const R = require('ramda');

const nameIsIncluded = (array) => R.compose(
  R.includes(R.__, array),
  R.prop('name')
);

const hasAtLeastOneDocumentOfType = type => R.compose(
  R.gte(R.__, 1),
  R.length,
  R.filter(R.propEq('documentType', type))
);

const getRequiredDocumentsValidator = R.compose(
  R.map(
    R.compose(
      hasAtLeastOneDocumentOfType,
      R.prop('value')
    )
  ),
  R.filter(R.propEq('required', true))
);

module.exports = {
  nameIsIncluded,
  hasAtLeastOneDocumentOfType,
  getRequiredDocumentsValidator
};
