
const rolesList = {
  TNS: [
    'entreprise en nom propre',
    'personne physique'
  ],
  COMPANY: 'personne morale'
};

Object.freeze(rolesList);

module.exports = rolesList;
